.main {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
}

.image {
  width: calc(100% - 40px);
  max-width: 50rem;
  height: 60vh;
}

.logo {
  width: 100%;
  height: 100%;
}

.button {
  background-color: transparent;
  border: 2px solid var(--color-white);
  border-radius: 14px;
  color: var(--color-white);
  font-size: 2rem;
  padding: 2rem 4rem;
  cursor: pointer;
  user-select: none;
  margin-bottom: 3.5rem;
}

.button:focus {
  box-shadow: 0px 0px 20px var(--color-white);
}

@media(max-width: 700px) {
  .button {
    padding: 1.5rem 3rem;
    font-size: 1.5rem;
  }
}