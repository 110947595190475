.main {
  padding: 0px 20px 20px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 1000px;
  /* View transition API - experimental */
  view-transition-name: level;
  contain: layout;
}

.instructions {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.question {
  margin: 0 0 .8rem;
  color: var(--color-white);
  font-weight: 400;
  text-align: center;
  font-size: 1.6rem;
  padding: 0 20px;
}

.question strong {
  text-decoration: underline;
}

.hint {
  margin: 0 0 15px;
  color: var(--color-white);
  font-weight: 400;
  text-align: center;
  font-style: italic;
  padding: 0 20px;
  font-size: .9rem;
}

.emoji {
  font-style: normal;
}

.words {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  user-select: none;
  pointer-events: none;
}

.word {
  margin: 1rem;
  font-weight: 900;
  font-size: 2.4rem;
}

.buttons {
  margin-top: 4rem;
  max-height: 15rem;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

.button {
  flex-grow: 1;
  margin: 20px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid var(--color-white);
  color: var(--color-white);
  font-size: 1.5rem;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 0px transparent;
  border-radius: 1rem;
  font-weight: 900;
  font-size: 1.5rem;
  user-select: none;
}

.buttons[data-options="2"] .button {
  flex-basis: calc(50% - 40px);
}

.buttons[data-options="3"] .button {
  flex-basis: calc(33.33% - 40px);
}

.buttons[data-options="4"] .button {
  flex-basis: calc(25% - 40px);
}

.button:focus {
  cursor: pointer;
  box-shadow: 0px 0px 20px var(--color-white);
}

@media(max-width: 800px) {
  .buttons[data-options="4"] .button {
    flex-basis: calc(50% - 40px);
  }
}

@media(max-width: 600px) {
  .buttons {
    flex-direction: column;
  }
  .word {
    font-weight: 900;
    font-size: 1.7rem;
    margin: .6rem;
  }
  .button {
    font-size: 1.2rem;
  }
}