[data-color="red"] {
  color: var(--color-red);
}

[data-color="orange"] {
  color: var(--color-orange);
}

[data-color="yellow"] {
  color: var(--color-yellow);
}

[data-color="green"] {
  color: var(--color-green);
}

[data-color="blue"] {
  color: var(--color-blue);
}

[data-color="purple"] {
  color: var(--color-purple);
}

[data-color="pink"] {
  color: var(--color-pink);
}

[data-color="brown"] {
  color: var(--color-brown);
}

[data-bg="red"] {
  background-color: var(--color-red);
}

[data-bg="orange"] {
  background-color: var(--color-orange);
}

[data-bg="yellow"] {
  background-color: var(--color-yellow);
}

[data-bg="green"] {
  background-color: var(--color-green);
}

[data-bg="blue"] {
  background-color: var(--color-blue);
}

[data-bg="purple"] {
  background-color: var(--color-purple);
}

[data-bg="pink"] {
  background-color: var(--color-pink);
}

[data-bg="brown"] {
  background-color: var(--color-brown);
}