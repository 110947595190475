.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--height-header);
  background-color: var(--color-main);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  color: var(--color-white);
  border-bottom: 1px solid var(--color-white);
  /* View transition API - experimental */
  view-transition-name: header;
  contain: layout;
}

.progress {
  position: fixed;
  top: var(--height-header);
  left: 0;
  right: 0;
  width: 100%;
  height: 5px;
  /* View transition API - experimental */
  view-transition-name: progress;
  contain: layout;
}

.progressDetail {
  height: 100%;
  background-color: var(--color-white);
  transform: all 0.5s ease;
}

.home {
  cursor: pointer;
  display: flex;
  background-color: transparent;
  border: none;
}

.homeColor {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: 0 2px;
}

.logo {
  width: 6rem;
  height: 2rem;
  display: flex;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.main {
  padding-top: calc(2 * var(--height-header));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
}