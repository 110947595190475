* {
  box-sizing: border-box;
}

html {
  background-color: var(--color-main);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --color-main: #121212;
  --color-white: #ffffff;

  --color-red: #ff5630;
  --color-orange: #ffa500;
  --color-yellow: #fcf75e;
  --color-green: #36b37e;
  --color-blue: #00b8d9;
  --color-purple: #6554c0;
  --color-pink: #ff43a9;
  --color-brown: #966F33;

  --height-header: 50px;
}